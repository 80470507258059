import { ready } from 'core-utilities';
import React from 'react';
import { render } from 'react-dom';
import AgeRecommendationTitle from './containers/AgeRecommendationTitle';
import '../../../css/gameGuidelines/gameGuidelines.scss';

const ageRecommendationTitleContainer = 'game-age-recommendation-container';
const ageRecommendationDetailContainer = 'game-age-recommendation-details';

ready(() => {
  if (document.getElementById(ageRecommendationDetailContainer)) {
    render(
      <AgeRecommendationTitle isDisplayAgeRecommendationDetails />,
      document.getElementById(ageRecommendationDetailContainer)
    );
  }
  if (document.getElementById(ageRecommendationTitleContainer)) {
    render(
      <AgeRecommendationTitle isDisplayAgeRecommendationDetails={false} />,
      document.getElementById(ageRecommendationTitleContainer)
    );
  }
});
